<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0 shadow-box">
        <b-link class="brand-logo">
          <vuexy-logo style="height: 6em;" />
        </b-link>

        <b-card-text class="mb-2 text-center">
          Please sign-in to your account and start the adventure
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginValidation" #default="{invalid}">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-overlay :show="show" rounded="sm" opacity="0.2" no-fade>
              <b-form-group label-for="email" label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    name="login-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      placeholder="Password"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                @click="login"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>

              <div
                class="mt-1 mb-1 d-flex align-items-center justify-content-center"
              >
                <router-link to="/password-reset">
                  Forgot my password</router-link
                >
              </div>
            </b-overlay>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BSpinner,
  BOverlay,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import router from "@/router";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      show: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.show = true;
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              /*  if (response.data.userData.id == 1) {
                  response.data.userData.ability = [
                    {
                      action: "manage",
                      subject: "all",
                    },
                  ];
                } else {
                  response.data.userData.ability = [
                    {
                      action: "read",
                      subject: "Auth",
                    },
                  ];
                }*/

              if (response.data.error == "Unauthorized") {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Error`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: `Incorrect login details, please try again.`,
                  },
                });
                this.show = false;
              } else {
                const { userData } = response.data;
                useJwt.setToken(response.data.accessToken);
                useJwt.setTokenExpiry(response.data.expireTime);

                useJwt.setRefreshToken(response.data.accessToken);
                localStorage.setItem("userData", JSON.stringify(userData));
                localStorage.setItem(
                  "userPerms",
                  JSON.stringify(response.data.userPerms)
                );

                /* this.$ability.update(userData.ability);*/
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome ${userData.name || userData.surname}`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                  },
                });

                this.show = false;
                  router.push({ path: "/" });
              
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: `Error occurred while logging in`,
                },
              });
              this.show = false;
              this.$refs.loginValidation.setErrors(error.response.data.error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.shadow-box {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}
</style>
